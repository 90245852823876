
















import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import AbpBase from '../../../../lib/abpbase'
import Company from '../../../../store/entities/companycontext/company'
import CompanyForm from "@/views/setting/companycontext/company/form-company.vue";

@Component({
    components: {CompanyForm}
})
export default class CreateCompany extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    private company: Company = new Company();

    save() {
        let companyForm = ((this.$refs.form as any).$refs.companyForm as any);
        companyForm.validate(async (valid: boolean) => {
            if (valid) {
                let response = await this.$store.dispatch({
                    type: 'company/create',
                    data: (this.$refs.form as any).getCompany()
                });
                companyForm.resetFields();
                this.$emit('save-success', response);
                this.$emit('input', false);
            }
        })
    }

    cancel() {
        ((this.$refs.form as any).$refs.companyForm as any).resetFields();
        this.$emit('input', false);
    }

    visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            this.company = this.$store.state.company.editCompany;
        }
    }
}
